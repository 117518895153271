import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StrapiImage = ({ imageInfo, Addclass }) => {

if(imageInfo === null ){
return <></>

}else{

  const { alt = '', childImageSharp, image} = imageInfo

  if (!!imageInfo.childImageSharp) {
    return (
      <GatsbyImage className={Addclass} image={getImage(imageInfo.childImageSharp)} alt={alt} />
    )
  }


  if (!childImageSharp && imageInfo.extension === 'svg') {
  return <img className={Addclass} src={imageInfo.publicURL} alt={alt} />
}

  if (!!image && typeof image === 'string'){
    return <img className={Addclass} src={image} alt={alt} />

}
  return null
}
}


export default StrapiImage
