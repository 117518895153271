import React, { Component } from "react";
// import fragment from './shaders/fragment.glsl';
// import vertex from './shaders/vertex.glsl';
import {fragment,vertex} from './shaders/shaders.js';
import {Uniform,Rect} from './utils.js';

// import GyroNorm from './lib/gyronorm';
// const gn = new GyroNorm.GyroNorm();
const MAX_TIMESTEP = 67; // max 67 ms/frame


class DepthImage extends Component {

  constructor(props) {
    super(props)
      this.state = {
        Loaded:false
      }


      // For timing


      this.displayWidth = typeof window !== 'undefined' ? window.innerWidth : 0
      this.displayHeight =  typeof window !== 'undefined' ? window.innerHeight : 0
      this.frameId = null
      this.textures = [];
      this.assets =[]
      this.images =[]
      this.frontImages = []
      this.device = null


      this.container = null
      this.canvas = null
      this.gl = null
      this.ratio = 0
      this.windowWidth = 0
      this.windowHeight = 0
      this.mouseX = 0;
      this.mouseY = 0;


      this.vth = 40
      this.hth = 40

      this.device = null

      this.mouseTargetX = 0;
      this.mouseTargetY = 0;
      this.program = null



      this.resize = this.resize.bind(this)
      this.doFrame = this.doFrame.bind(this)
      this.renderScene = this.renderScene.bind(this)
      this.update = this.update.bind(this)
      this.loadImages = this.loadImages.bind(this)
      this.loadImage = this.loadImage.bind(this)
      this.addShader = this.addShader.bind(this)
      this.setup = this.setup.bind(this)
      this.mouseMove = this.mouseMove.bind(this)
      this.stop = this.stop.bind(this)
      // this.gyro = this.gyro.bind(this)




    }




  componentDidMount() {

    // let Textures = [
    //   {name: 'image', type:"back" , url: require('./images/FOW.jpg')},
    //   {name: 'depth', type:"back" , url: require('./images/FOW_DEPTH.jpg')},
    //   {name: 'device', type:"device", url: require('./images/World-Device.svg')},
    //   {name: 'image', type:"front", url: require('./images/Delivering_sustainable_legacies_foreground.png')}
    // ]

    let Textures = [
      {name: 'image', type:"back" , url: this.props.dataImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src},
      {name: 'depth', type:"back" , url: this.props.dataDepth.localFile.childImageSharp.gatsbyImageData.images.fallback.src}
    ]


  // this.container = document.getElementById(ID);
  this.canvas = document.createElement('canvas');
  // this.mount.appendChild( this.canvas  );
  this.container.appendChild(this.canvas)
  this.gl = this.canvas.getContext("webgl")||this.canvas.getContext("experimental-webgl")

  this.ratio = window.devicePixelRatio;
  this.windowWidth = window.innerWidth;
  this.windowHeight = window.innerHeight;

  this.mouseX = 0;
  this.mouseY = 0;

  this.mouseTargetX = 0;
  this.mouseTargetY = 0;

  if(this.props.reduceMotion){
    this.vth = 80
    this.hth = 80
  }


this.loadImages(Textures)


  }


loadImage(url, callback) {
    var image = new Image();
    image.crossOrigin=""
    image.src = url;
    image.onload = callback;
    return image;
  }

loadImages(urls) {

    var imagesToLoad = urls.length;
    let This = this
    // Called each time an image finished loading.
    var onImageLoad = function() {
      --imagesToLoad;
      // If all the images are loaded call the callback.
      if (imagesToLoad === 0) {
        This.setup()
      }
    };
for(let url of urls){
  var image = this.loadImage(url.url, onImageLoad);

      this.assets.push({[url.name]:image});
      if(url.type ==="back"){
        this.images.push(image)
      }
      if(url.type === "device"){
        this.device = image
      }
      if(url.type === "front"){
        this.frontImages.push(image)
      }
}

  }

  addShader( source, type ) {
  let shader = this.gl.createShader( type );
  this.gl.shaderSource( shader, source );
  this.gl.compileShader( shader );
  let isCompiled = this.gl.getShaderParameter( shader, this.gl.COMPILE_STATUS );
  if ( !isCompiled ) {
    throw new Error( 'Shader compile error: ' + this.gl.getShaderInfoLog( shader ) );
  }
  this.gl.attachShader( this.program, shader );
}



setup(){

  this.program = this.gl.createProgram();
  // this.gl.attachShader( this.program, vertex );
  //   this.gl.attachShader( this.program, fragment );
  //
  this.addShader( vertex, this.gl.VERTEX_SHADER );
  this.addShader( fragment, this.gl.FRAGMENT_SHADER );

  this.gl.linkProgram( this.program );
  this.gl.useProgram( this.program );

  this.uResolution = new Uniform( 'resolution', '4f' , this.program, this.gl );
  this.uMouse = new Uniform( 'mouse', '2f' , this.program, this.gl );
  this.uTime = new Uniform( 'time', '1f' , this.program, this.gl );
  this.uRatio = new Uniform( 'pixelRatio', '1f' , this.program, this.gl );
  this.uThreshold = new Uniform( 'threshold', '2f' , this.program, this.gl );
  // create position attrib
  this.billboard = new Rect( this.gl );
  this.positionLocation = this.gl.getAttribLocation( this.program, 'a_position' );
  this.gl.enableVertexAttribArray( this.positionLocation );
  this.gl.vertexAttribPointer( this.positionLocation, 2, this.gl.FLOAT, false, 0, 0 );

  let that = this;
  let gl = that.gl;

  this.imageAspect = this.images[0].naturalHeight/this.images[0].naturalWidth;
    for (var i = 0; i < this.images.length; i++) {


      let texture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, texture);

      // Set the parameters so we can render any size image.
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

      // Upload the image into the texture.
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, this.images[i]);
      this.textures.push(texture);
    }

  // lookup the sampler locations.
  let u_image0Location = this.gl.getUniformLocation(this.program, 'image0');
  let u_image1Location = this.gl.getUniformLocation(this.program, 'image1');

  // set which texture units to render with.
  this.gl.uniform1i(u_image0Location, 0); // texture unit 0
  this.gl.uniform1i(u_image1Location, 1); // texture unit 1

  this.gl.activeTexture(this.gl.TEXTURE0);
  this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[0]);
  this.gl.activeTexture(this.gl.TEXTURE1);
  this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[1]);




  this.deviceCanvas=document.createElement('canvas');

  let svg=new Image();
  svg.src = this.device

  var ctx = this.deviceCanvas.getContext('2d');
    this.deviceCanvas.width=400;
    this.deviceCanvas.height=400;
    ctx.drawImage(svg,0,0);






  this.prevT = Date.now() // prev frame time (ms)
  this.simT = 0 // total running time (ms)
  this.resize(this.displayWidth, this.displayHeight)

  if(typeof window !== 'undefined'){
    window.addEventListener('resize', this.resize);
  }
// this.mount.appendChild( this.renderer.domElement );
this.setState({Loaded:true})
this.mouseMove();
// this.gyro();
this.doFrame()


}




/** Handle window resize events */
resize(w, h) {

  this.windowWidth = window.innerWidth;
  this.windowHeight = window.innerHeight;
  this.width = this.container.offsetWidth;
  this.height = this.container.offsetHeight;



  this.canvas.width = this.width*this.ratio;
  this.canvas.height = this.height*this.ratio;
  this.canvas.style.width = this.width + 'px';
  this.canvas.style.height = this.height + 'px';
  let a1,a2;
  if(this.height/this.width<this.imageAspect) {
    a1 = 1;
    a2 = (this.height/this.width) / this.imageAspect;
  } else{
    a1 = (this.width/this.height) * this.imageAspect ;
    a2 = 1;
  }
  this.uResolution.set( this.width, this.height, a1, a2 );
  this.uRatio.set( 1/this.ratio );
  this.uThreshold.set( this.hth, this.vth );
  this.gl.viewport( 0, 0, this.width*this.ratio, this.height*this.ratio );

}

// gyro() {
//
//     let that = this;
//
//     this.maxTilt = 15;
//
//
//     const rotationCoef = 0.15;
//
//     gn.init({ gravityNormalized: true }).then(function() {
//       gn.start(function(data) {
//       let x = 0
//       let y= 0
//
// if(that.windowWidth > that.windowHeight){
//   y = data.do.beta;
//   x = data.do.gamma;
// }else{
//   y = data.do.gamma;
//   x = data.do.beta;
// }
// // let y = data.do.gamma;
// // let x = data.do.beta;
//
//         that.mouseTargetY = clamp(x,-that.maxTilt, that.maxTilt)/that.maxTilt;
//         that.mouseTargetX = -clamp(y,-that.maxTilt, that.maxTilt)/that.maxTilt;
//
//       });
//     }).catch(function(e) {
//       console.log('not supported');
//
//     });
//
//   }

  mouseMove() {
  	let that = this;
  	document.addEventListener('mousemove', function(e) {
      let halfX = that.windowWidth/2;
      let halfY = that.windowHeight/2;

  		that.mouseTargetX = (halfX - e.clientX)/halfX;
  		that.mouseTargetY = (halfY - e.clientY)/halfY;


  	});
  }




doFrame(noscroll) {

const curT = Date.now();
let dt = curT - this.prevT
// fpsMon.update(dt)
if(this.props.inview){
if (dt > 0) {
  // only do computations if time elapsed
  if (dt > MAX_TIMESTEP) {
    // don't exceed max timestep
    dt = MAX_TIMESTEP
    this.prevT = curT - MAX_TIMESTEP
  }
  // update sim
  this.update(dt,noscroll)
  // render it
  this.renderScene()
  // remember prev frame time
  this.prevT = curT
}
}

this.frameId = requestAnimationFrame(this.doFrame)

}



renderScene () {
      this.billboard.render( this.gl );
  // this.renderer.render(this.scene, this.camera)
}





update(dt, noscroll) {


  this.mouseX += (this.mouseTargetX - this.mouseX)*0.05;
this.mouseY += (this.mouseTargetY - this.mouseY)*0.05;


this.uMouse.set( this.mouseX, this.mouseY );

// update animations here


this.simT += dt


}






      componentWillUnmount(){

          if(typeof window !== "undefined"){
          window.removeEventListener("resize", this.resize);
        }
          if(this.renderer !== undefined && this.renderer !== null){
            // this.container.removeChild(this.renderer.domElement)
          }
this.stop()

        }

        // stop = () => {
        //
        //
        // }

  stop(){
    cancelAnimationFrame(this.frameId)
  }









render() {
    return (
      <div ref={ref => (this.container = ref)} />
    )
  }
}
export default DepthImage;
